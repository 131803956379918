@function is-important($important){
  @return #{if($important, '!important', '')};
}

@mixin theme($property: null, $key: null, $important: false) {
  & {
    #{$property}: mat-color(map_get($light-theme, $key)) is-important($important);
  }

  :host-context(.green-theme) & {
    #{$property}: mat-color(map_get($green-theme, $key)) is-important($important);
  }
  .green-theme & {
    #{$property}: mat-color(map_get($green-theme, $key)) is-important($important);
  }

  :host-context(.dark-blue-theme) & {
    #{$property}: mat-color(map_get($dark-blue-theme, $key)) is-important($important);
  }
  .dark-blue-theme & {
    #{$property}: mat-color(map_get($dark-blue-theme, $key)) is-important($important);
  }

  :host-context(.dark-theme) & {
    #{$property}: mat-color(map_get($dark-theme, $key)) is-important($important);
  }
  .dark-theme & {
    #{$property}: mat-color(map_get($dark-theme, $key)) is-important($important);
  }
}

@mixin glass-card($color-key: null, $important: false) {
  & {
    // background: lighten(mat-color(map_get($light-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($light-theme, $color-key))), green(mat-color(map_get($light-theme, $color-key))), blue(mat-color(map_get($light-theme, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($light-theme, $color-key))), green(mat-color(map_get($light-theme, $color-key))), blue(mat-color(map_get($light-theme, $color-key))), 0.1) is-important($important);

    // transform: perspective(0700px) rotateY(3deg);
  }

  :host-context(.green-theme) & {
    // background: lighten(mat-color(map_get($green-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), 0.1) is-important($important);
  }
  .green-theme & {
    // background: lighten(mat-color(map_get($green-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), 0.1) is-important($important);
  }
  
  :host-context(.dark-blue-theme) & {
    background: lighten(rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($dark-blue-theme, $color-key))), 0.3), 70) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($dark-blue-theme, $color-key))), 0.1) is-important($important);
    // Add more blur because the colors are to dark to be visible
    backdrop-filter: blur(32px);
  }
  .dark-blue-theme & {
    background: lighten(rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($dark-blue-theme, $color-key))), 0.3), 70) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($dark-blue-theme, $color-key))), 0.1) is-important($important);
    // Add more blur because the colors are to dark to be visible
    backdrop-filter: blur(32px);
  }

  :host-context(.dark-theme) & {
    // background: lighten(mat-color(map_get($green-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), 0.1) is-important($important);
  }
  .dark-theme & {
    // background: lighten(mat-color(map_get($dark-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), 0.1) is-important($important);
  }
}

@mixin lighter-bg($color-key: null, $lighting: null, $important: false) {
  & {
    background: lighten(mat-color(map_get($light-theme, $color-key)), $lighting) is-important($important);
  }

  :host-context(.green-theme) & {
    background: lighten(mat-color(map_get($green-theme, $color-key)), $lighting) is-important($important);
  }
  .green-theme & {
    background: lighten(mat-color(map_get($green-theme, $color-key)), $lighting) is-important($important);
  }

  :host-context(.dark-blue-theme) & {
    background: lighten(mat-color(map_get($dark-blue-theme, $color-key)), $lighting) is-important($important);
  }
  .dark-blue-theme & {
    background: lighten(mat-color(map_get($dark-blue-theme, $color-key)), $lighting) is-important($important);
  }
  
  :host-context(.dark-theme) & {
    background: lighten(mat-color(map_get($dark-theme, $color-key)), $lighting) is-important($important);
  }
  .dark-theme & {
    background: lighten(mat-color(map_get($dark-theme, $color-key)), $lighting) is-important($important);
  }
}

@mixin lighter-gradient-bg($color-key: null, $lighting: null, $important: false) {
  & {
    background: linear-gradient(116.82deg, mat-color(map_get($light-theme, $color-key)), lighten(mat-color(map_get($light-theme, $color-key)), $lighting) 100%) is-important($important);
  }

  :host-context(.green-theme) & {
    background: linear-gradient(116.82deg, mat-color(map_get($green-theme, $color-key)), lighten(mat-color(map_get($green-theme, $color-key)), $lighting) 100%) is-important($important);
  }
  .green-theme & {
    background: linear-gradient(116.82deg, mat-color(map_get($green-theme, $color-key)), lighten(mat-color(map_get($green-theme, $color-key)), $lighting) 100%) is-important($important);
  }
  
  :host-context(.dark-blue-theme) & {
    background: linear-gradient(116.82deg, mat-color(map_get($dark-blue-theme, $color-key)), lighten(mat-color(map_get($dark-blue-theme, $color-key)), $lighting) 100%) is-important($important);
  }
  .dark-blue-theme & {
    background: linear-gradient(116.82deg, mat-color(map_get($dark-blue-theme, $color-key)), lighten(mat-color(map_get($dark-blue-theme, $color-key)), $lighting) 100%) is-important($important);
  }

  :host-context(.dark-theme) & {
    background: linear-gradient(116.82deg, mat-color(map_get($dark-theme, $color-key)), lighten(mat-color(map_get($dark-theme, $color-key)), $lighting) 100%) is-important($important);
  }
  .dark-theme & {
    background: linear-gradient(116.82deg, mat-color(map_get($dark-theme, $color-key)), lighten(mat-color(map_get($dark-theme, $color-key)), $lighting) 100%) is-important($important);
  }
}

@mixin transparent-shadow($color-key: null, $transparency: null, $important: false) {
  & { 
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($light-theme, $color-key))), green(mat-color(map_get($light-theme, $color-key))), blue(mat-color(map_get($light-theme, $color-key))), $transparency) is-important($important);
  }

  :host-context(.green-theme) & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), $transparency) is-important($important);
  }
  .green-theme & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), $transparency) is-important($important);
  }
  
  :host-context(.dark-blue-theme) & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($dark-blue-theme, $color-key))), $transparency) is-important($important);
  }
  .dark-blue-theme & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-theme) & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), +(($transparency) - 0.05)) is-important($important);
  }
  .dark-theme & {
    box-shadow: 0px 0px 20px 20px rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), +(($transparency) - 0.05)) is-important($important);
  }
}

@mixin transparent-bg($color-key: null, $transparency: null, $important: false) {
  & {
    background-color: rgba(red(mat-color(map_get($light-theme, $color-key))), green(mat-color(map_get($light-theme, $color-key))), blue(mat-color(map_get($light-theme, $color-key))), $transparency) is-important($important);
  }

  :host-context(.green-theme) & {
    background-color: rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), $transparency) is-important($important);
  }
  .green-theme & {
    background-color: rgba(red(mat-color(map_get($green-theme, $color-key))), green(mat-color(map_get($green-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-blue-theme) & {
    background-color: rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($dark-blue-theme, $color-key))), $transparency) is-important($important);
  }
  .dark-blue-theme & {
    background-color: rgba(red(mat-color(map_get($dark-blue-theme, $color-key))), green(mat-color(map_get($dark-blue-theme, $color-key))), blue(mat-color(map_get($green-theme, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-theme) & {
    background-color: rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), $transparency) is-important($important);
  }
  .dark-theme & {
    background-color: rgba(red(mat-color(map_get($dark-theme, $color-key))), green(mat-color(map_get($dark-theme, $color-key))), blue(mat-color(map_get($dark-theme, $color-key))), $transparency) is-important($important);
  }
}

@mixin custom-theme($theme: null, $property: null, $key: null, $important: false) {
  $themeSelector: map-get((light-theme: $light-theme,dark-theme: $dark-theme, green-theme: $green-theme, dark-blue-theme: $dark-blue-theme), $theme);

  #{$property}: mat-color(map_get($themeSelector, $key)) is-important($important);
}

@mixin custom-theme-transparent-shadow($theme: null, $color-key, $transparency: null, $important: false) {
  $themeSelector: map-get((light-theme: $light-theme,dark-theme: $dark-theme, green-theme: $green-theme, dark-blue-theme: $dark-blue-theme), $theme);

  & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

  :host-context(.green-theme) & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
  .green-theme & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-blue-theme) & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
  .dark-blue-theme & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-theme) & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
  .dark-theme & {
    box-shadow: 0px 0px 50px 30px rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
}


@mixin custom-theme-transparent-bg($theme: null, $color-key: null, $transparency: null, $important: false) {
  $themeSelector: map-get((light-theme: $light-theme,dark-theme: $dark-theme, green-theme: $green-theme, dark-blue-theme: $dark-blue-theme), $theme);

  & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

  :host-context(.green-theme) & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
  .green-theme & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-blue-theme) & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
  .dark-blue-theme & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

  :host-context(.dark-theme) & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }
  .dark-theme & {
    background: rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), $transparency) is-important($important);
  }

}


@mixin custom-theme-glass-card($theme: null, $color-key: null, $important: false) {
  $themeSelector: map-get((light-theme: $light-theme,dark-theme: $dark-theme, green-theme: $green-theme, dark-blue-theme: $dark-blue-theme), $theme);
 
  & {
    // background: lighten(mat-color(map_get($light-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($light-theme, $color-key))), 0.1) is-important($important);

    // transform: perspective(0700px) rotateY(3deg);
  }

  :host-context(.green-theme) & {
    // background: lighten(mat-color(map_get($green-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1) is-important($important);
  }
  .green-theme & {
    // background: lighten(mat-color(map_get($themeSelector, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1) is-important($important);
  }
  
  :host-context(.dark-blue-theme) & {
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.3), 70) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1) is-important($important);
    // Add more blur because the colors are to dark to be visible
    backdrop-filter: blur(32px);
  }
  .dark-blue-theme & {
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.3), 70) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1) is-important($important);
    // Add more blur because the colors are to dark to be visible
    backdrop-filter: blur(32px);
  }
  
  :host-context(.dark-theme) & {
    // background: lighten(mat-color(map_get($green-theme, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1) is-important($important);
  }
  .dark-theme & {
    // background: lighten(mat-color(map_get($themeSelector, $color-key)), 30);
    background: lighten(rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1), 50) is-important($important);
    border: 2px solid rgba(red(mat-color(map_get($themeSelector, $color-key))), green(mat-color(map_get($themeSelector, $color-key))), blue(mat-color(map_get($themeSelector, $color-key))), 0.1) is-important($important);
  }
}

@mixin d-none-for($theme: null, $important: false) {
  :host-context(.#{$theme}) & {
    display: none is-important($important);
  }
  .#{$theme} & {
    display: none is-important($important);
  }
}

@mixin d-block-for($theme: null, $important: false) {
  :host-context(.#{$theme}) & {
    display: block is-important($important);
  }
  .#{$theme} & {
    display: block is-important($important);
  }
}

@import "~bootstrap/scss/mixins/_breakpoints";

* {
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.dark-theme {
  ::-moz-selection {
    /* Code for Firefox */
    color: $dark;
    @include theme(background-color, primary);
  }

  ::selection {
    color: $dark;
    @include theme(background-color, primary);
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  @include theme(background-color, primary);
}

::selection {
  color: white;
  @include theme(background-color, primary);
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  // background-color: white;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow-y: overlay;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 1.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: none;
  @include transparent-bg(background, 0.2);
}

body::-webkit-scrollbar-thumb {
  @include theme(background-color, primary);
  border-radius: 1.5em;
}

.body-container {
  width: 100vw;
  transition: background-color 0.8s ease-in-out;

  &.dark-theme {
    @include custom-theme(dark-theme, background-color, background, true);
    // background-color: $gray-900;
  }
}

[tabindex]:focus-visible {
  outline: none;
  border: 2px $primary solid;

  // &::after{
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   border: 2px $white solid !important;
  //   border-radius: inherit;
  //   opacity: 0.8;
  // }
}

.container {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.subtitle {
  font-size: xx-large;
  line-height: 100%;
  font-weight: 700;
  margin-bottom: 50px;
  line-height: 100%;
  @include theme(color, background);
  transition: color 0.8s ease-in-out;
}

.dark-theme .subtitle {
  @include custom-theme(dark-theme, color, primary, true);
}

.title {
  z-index: 0;
  padding: 1rem 0;
  // padding-top: 60px;
  font-style: normal;
  font-weight: 600;
  // font-size: 142px;
  font-size: clamp(52px, 8vw, 132px);
  // color: #00A3C7;
  // margin-bottom: 50px;
  top: 0px;
  line-height: 100%;
  @include theme(color, primary);
  transition: color 0.8s ease-in-out;
}

.dark-theme .title {
  @include custom-theme(dark-theme, color, primary, true);
}

.card-title {
  @include media-breakpoint-down(md) {
    padding-left: 32px;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 24px;
  }
  padding-left: 40px;
  transition: color 0.8s ease-in-out;
}

.action-button {
  // background-color: white;
  padding: 0.5rem 1.5rem !important;
  border-radius: 100px !important;
  font-weight: 900;
  transition: all 0.2s ease-in, background-color 0.8s ease-in-out,
    color 0.8s ease-in-out;
  user-select: none;

  .mat-button-focus-overlay {
    background-color: $white;
  }

  &:focus-visible {
    outline: none;

    .mat-button-focus-overlay {
      opacity: 0.4 !important;
    }
  }

  &:hover {
    transform: translate(-1px, -2px);
    box-shadow: 0px 4px 10px 4px rgb(0 0 0 / 8%) !important;

    .mat-button-focus-overlay {
      opacity: 0;
    }
  }

  &:active {
    transform: translate(1px, 2px);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
  }

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

.thin-action-button {
  border-radius: 8px !important;
  padding: 0.5em 1.5em !important;

  background: linear-gradient(116.82deg, #00a3c7, #00d1ff 100%) !important;
  cursor: pointer;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  // text-shadow: 1px 2px 14px rgb(0 0 0 / 20%);
  font-weight: 700;

  &::before,
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-out;
    opacity: 0;
    border-radius: inherit;
  }
  &::before {
    background: linear-gradient(116.82deg, #00a3c7, #00d1ff 100%);
    transform: translate3d(0px, 0px, 0) scale(0.9);
    filter: blur(18px);
  }
  &::after {
    background: inherit;
    transition: all 0.3s ease-out;
  }
  &:hover {
    box-shadow: none !important;

    &:before {
      opacity: 0.7 !important;
    }
    &::after {
      opacity: 0.7 !important;
    }
  }
  &:focus-visible {
    outline: none;
    border-width: 1px;
    background: linear-gradient(
      116.82deg,
      lighten(#00a3c7, 15),
      lighten(#00d1ff, 15) 100%
    );
  }
  &:active {
    transform: translate(1px, 2px);
    box-shadow: none !important;

    &:before {
      opacity: 0 !important;
    }
    &::after {
      opacity: 0 !important;
    }
  }
}

.dark-theme .action-button {
  @include custom-theme(dark-theme, background-color, accent, true);
  @include custom-theme(dark-theme, color, primary, true);
}

button.mat-primary {
  @include theme(background-color, primary);
  @include theme(color, background);
}

.dark-theme button.mat-primary {
  @include custom-theme(dark-theme, background-color, accent, true);
  @include custom-theme(dark-theme, color, primary, true);
}

.color-primary {
  @include theme(color, primary);
}

.dark-theme .primary-gradient-footer-button-on-dark,
.primary-gradient-button {
  background: linear-gradient(116.82deg, #00a3c7, #00d1ff 100%) !important;
  cursor: pointer;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem !important;
  position: relative;
  z-index: 0;
  // text-shadow: 1px 2px 14px rgb(0 0 0 / 20%);
  font-weight: 700;

  &::before,
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-out;
    opacity: 0;
    border-radius: inherit;
  }

  &::before {
    background: linear-gradient(116.82deg, #00a3c7, #00d1ff 100%);
    transform: translate3d(0px, 0px, 0) scale(0.95);
    filter: blur(22px);
  }

  &::after {
    background: inherit;
    transition: all 0.3s ease-out;
  }

  &:hover {
    box-shadow: none !important;

    &:before {
      opacity: 0.7 !important;
    }
    &::after {
      opacity: 0.7 !important;
    }
  }

  &:focus-visible {
    outline: none;
    border-width: 1px;
    background: linear-gradient(
      116.82deg,
      lighten(#00a3c7, 15),
      lighten(#00d1ff, 15) 100%
    );
  }

  &:active {
    transform: translate(1px, 2px);
    box-shadow: none !important;

    &:before {
      opacity: 0 !important;
    }
    &::after {
      opacity: 0 !important;
    }
  }
}
.primary-gradient-footer-button-on-dark {
  @include theme(color, primary, true);
  padding: 2rem !important;
  border-radius: 100px !important;
  font-weight: bold !important;
  font-size: 24px !important;
  transition: all 0.2s ease-in, background-color 0.8s ease-in-out,
    color 0.8s ease-in-out;

  &:hover {
    transform: translate(-1px, -3px);
    box-shadow: 0px 4px 10px 4px rgb(0 0 0 / 10%) !important;
  }
  &:active {
    transform: translate(1px, 3px);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
  }
}
.dark-theme .primary-gradient-footer-button-on-dark {
  color: $white !important;
  padding: 2rem !important;
}

.dark-theme .color-primary {
  @include custom-theme(dark-theme, color, primary, true);
}

.izibac-circle .circle {
  background-color: #61e2ff;
}

.dark-theme .izibac-circle .circle {
  background-color: #61e2ff;
}

.bg-color-primary {
  @include theme(background-color, primary, true);
}

.color-white {
  color: $white !important;
}

.bg-color-white {
  background-color: $white !important;
}

.color-dark {
  color: $dark !important;
}

.bg-color-dark {
  background-color: $dark !important;
}

.color-ux {
  color: $green !important;
}
.dark-theme .color-ux {
  color: $white !important;
}

.bg-color-ux {
  background-color: $green !important;
}

.color-ui {
  color: $purple !important;
}
.dark-theme .color-ui {
  color: $white !important;
}

.bg-color-ui {
  background-color: $purple !important;
}

.color-frontend {
  color: $orange !important;
}
.dark-theme .color-frontend {
  color: $white !important;
}

.bg-color-frontend {
  background-color: $orange !important;
}

.color-backend {
  color: $blue !important;
}
.dark-theme .color-backend {
  color: $white !important;
}

.bg-color-backend {
  background-color: $blue !important;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.sticky-hidden {
  top: -100%;
  position: sticky;
}

.blue-text {
  color: #00a3c7;
}

.article-text {
  z-index: 1;
  font-size: 17px;
  line-height: 1.5;
  /* margin-bottom: 50px; */
  @include theme(color, foreground);
  transition: color 0.8s ease-in-out;
}

.offer-text {
  font-size: 18px;
  line-height: 1.5;
  transition: color 0.8s ease-in-out;
}

// .dark-theme .article-text {
//   @include custom-theme(dark-theme, color, primary);
// }

.text-body {
  transition: color 0.8s ease-in-out;
  @include theme(color, foreground, true);
}

.dark-theme .text-body {
  @include custom-theme(dark-theme, color, primary, true);
}

.text-white {
  color: $white !important;
}

.glass-card {
  @include glass-card(primary);
  @include theme(color, foreground);
  // color: #474747;

  mix-blend-mode: normal;
  backdrop-filter: blur(22px);
  border-radius: 30px;
  gap: 24px;

  // padding: 40px 0px 40px 0px;
  padding-top: 40px;
  padding-bottom: 40px;

  @include media-breakpoint-down(md) {
    padding-bottom: 32px;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 24px;
  }

  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    background-color 0.8s ease-in-out, border 0.8s ease-in-out,
    background 0.8s ease-in-out;
  -webkit-transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    background-color 0.8s ease-in-out, border 0.8s ease-in-out,
    background 0.8s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    background-color 0.8s ease-in-out, border 0.8s ease-in-out,
    background 0.8s ease-in-out;
  -o-transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    background-color 0.8s ease-in-out, border 0.8s ease-in-out,
    background 0.8s ease-in-out;

  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: perspective(1px) translateZ(0);

  &:hover {
    @include transparent-shadow(primary, 0.08);
    transform: translate(-2px, 0px);
  }
}

@-moz-document url-prefix() {
  .glass-card {
    transform: none;
    &:hover {
      transform: none;
    }
  }
}

.dark-theme .green-theme .glass-card {
  @include custom-theme-glass-card(dark-theme, accent);

  &:hover {
    @include custom-theme-transparent-shadow(dark-theme, primary, 0.03);
  }
}

.dark-theme .dark-blue-theme .glass-card {
  @include custom-theme-glass-card(dark-theme, accent);

  &:hover {
    @include custom-theme-transparent-shadow(dark-theme, primary, 0.03);
  }
}

.card-content {
  transition: transform 0.75s ease-in-out, box-shadow 0.75s ease-in-out,
    width 0.4s ease-in-out, opacity 0s ease, background-color 0.8s ease-in-out;
  -webkit-transition: transform 0.75s ease-in-out, box-shadow 0.75s ease-in-out,
    width 0.4s ease-in-out, opacity 0s ease, background-color 0.8s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out, box-shadow 0.75s ease-in-out,
    width 0.4s ease-in-out, opacity 0s ease, background-color 0.8s ease-in-out;
  -o-transition: transform 0.75s ease-in-out, box-shadow 0.75s ease-in-out,
    width 0.4s ease-in-out, opacity 0s ease, background-color 0.8s ease-in-out;

  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  // transform: perspective(1px) translateZ(0);

  @include media-breakpoint-down(md) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 24px;
    padding-right: 24px;
  }
  padding-left: 40px;
  padding-right: 40px;

  left: 0;
  // z-index: -1;
}

.mix-blend-mode {
  background-color: white;
}

.circle {
  @include lighter-bg(primary, 30);
}

.bg-top {
  @include lighter-gradient-bg(primary, 30);
  will-change: opacity, transform;
}

.dark-theme .bg-top {
  // background: linear-gradient(
  //   116.82deg, rgba(207, 207, 207, 0.9), rgba(72, 72, 72, 0.4) 100%) !important;

  // background: linear-gradient(116.82deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.45) 100%) !important;
  background: linear-gradient(
    120deg,
    rgb(0 0 0 / 0%),
    rgb(189 189 189 / 84%) 100%
  ); // background: url("./../assets/shapes/gradient.svg") no-repeat !important;
  // background-size: 100% !important;
}

.theme-toggler {
  left: -10px;
  bottom: -10px;
  height: 0px;
  width: 0px;
  opacity: 0.9;
  transition: all 0.3s ease-in-out, background-color 0.8s ease-in-out;
  // @include theme(background-color, primary);
  border-radius: 100%;
  position: fixed;
  cursor: pointer;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &.visible {
    height: clamp(32px, 20vw, 60px);
    width: clamp(32px, 20vw, 60px);
    left: 32px;
    bottom: 32px;
  }

  .icon-container {
    // @include custom-theme(dark-theme, background-color, background);
    // border: 2px solid rgba(255, 255, 255, 0.3);
  }
}

.dark-theme .theme-toggler .icon-container {
  // @include custom-theme(light-theme, background-color, primary);
}

.picture-backdrop {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  transition: all 0.4s ease-in-out, background-color 0.8s ease-in-out;
  z-index: 9;

  &.active {
    opacity: 1;
    background-color: rgba(73, 73, 73, 0.3);
    backdrop-filter: blur(16px);
  }
}

.dark-theme .picture-backdrop {
  &.active {
    background: rgb(0 0 0 / 60%);
    // @include transparent-bg(foreground, 0.6, true);
  }
}

.general-backdrop {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

#global-backdrop {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: all 0.4s ease-in-out, background-color 0.8s ease-in-out;
  z-index: 9;

  &.active-backdrop {
    pointer-events: all;
    opacity: 1;
    background-color: rgba(73, 73, 73, 0.3);
    backdrop-filter: blur(16px);
  }
}
.dark-theme #global-backdrop.active-backdrop {
  background: rgb(0 0 0 / 60%);
}

.gap-3 {
  gap: 3rem;
}

.gap-1 {
  gap: 1em;
}

.gap-2 {
  gap: 2rem;
}

li {
  padding: 0.75rem;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.border-radius-20 {
  border-radius: 20px;
}

.mobile-frame {
  max-height: 80vh;
  height: 700px;
  min-width: 340px;
  width: 340px;
  border: 3px solid $dark;
  background-color: black;
  border-radius: 35px;
  position: sticky;
  z-index: 1;
  overflow: hidden;
  top: 150px;
  float: right;
  transition: 0.4s ease-in-out;
  // will-change: top, left, margin, transform;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    float: none;
    position: relative;
    top: 0;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.desktop-frame {
  height: 600px;
  max-height: 70vh;
  width: 800px;
  min-width: 340px;
  border: 3px solid #85848b;
  background-color: black;
  border-radius: 10px;
  position: sticky;
  z-index: 1;
  // top: 10vh;
  top: 150px;
  float: right;
  transition: 0.4s ease-in-out;
  margin-bottom: 90px;
  // will-change: top, left, margin, transform;

  @media (max-width: map-get($grid-breakpoints, xl)) {
    float: none;
    position: relative;
    top: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 100%;
    margin-bottom: 8rem;
  }

  iframe {
    overflow: hidden;
    border-radius: 6px;
  }

  &::before {
    content: "";
    background-image: url("./../assets/shapes/imac-base.png");
    // transform: perspective(10px) rotateX(1deg);
    // border-radius: 0px 0px 3px 3px;
    position: absolute;
    top: calc(100% + 2px);
    left: 50%;
    width: 192px;
    height: 88px;
    transform: translate(-50%, 0%);
    // transform: translate(-50%, 0%) perspective(10px) rotateX(3deg);
    // background-color: $gray-300;
    // background: linear-gradient(180deg, #E4E4E4 0%, #A8A9AD 90.54%);
  }

  // &::after {
  // content: "";
  // position: absolute;
  // top: calc(100% + 81px);
  // left: 50%;
  // width: 240px;
  // height: 20px;
  // border-radius: 100%;
  // transform: translate(-50%, 0%);
  // // background-color: #A8A9AD;
  // background: radial-gradient(69.57% 69.57% at 50% 30.43%, #A8A9AD 0%, rgb(168 169 173 / 28%) 100%);
  // }

  &.prototype-frame-focused {
    height: 95vh;
    max-height: 100vh;
    width: 80vw;
    min-width: 340px;
    max-width: 1440px;

    &::after,
    &::before {
      display: none;
    }
  }
}

.hide-prototype-button {
  position: sticky;
  top: 0px;
  width: 100%;
  text-align: right;
}

.prototype-frame-focused {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.prototype-container {
  position: relative;
  transition: all 0.4s ease-in-out, background-color 0s;
}

.prototype-backdrop {
  transition: all 0.4s ease-in-out, background-color 0s;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  position: fixed;

  &.active {
    opacity: 1;
    background-color: rgba(73, 73, 73, 0.3);
    backdrop-filter: blur(16px);
  }
}

.dark-theme .prototype-backdrop {
  &.active {
    background: rgb(0 0 0 / 60%);
    // @include transparent-bg(background, 0.6, true);
  }
}

.close-button {
  position: fixed;

  top: 3vh;
  right: 3vw;

  @include media-breakpoint-down(sm) {
    top: 8px;
    right: 8px;
  }

  cursor: pointer;
  background-color: transparentize($color: $white, $amount: 0.3);
  padding: 24px;
  border-radius: 100%;
  z-index: 10;
  transition: transform ease-in-out 0.4s, background-color 0.8s ease-in-out;
  user-select: none;

  &:hover {
    background-color: transparentize($color: $white, $amount: 0.1);
    transform: rotate(90deg);
  }
}

.tech-item {
  padding-top: 24px;
}

.rating-stars {
  display: flex;
  justify-content: center;
  cursor: pointer;

  path {
    @include theme(fill, primary);
    transition: fill 0.8s ease-in-out;
  }

  &:hover .rating-star {
    opacity: 1;
  }

  .rating-star {
    opacity: 0.4;
    padding: 6px;

    &:hover ~ .rating-star {
      opacity: 0.4;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }
}

.dark-theme .rating-stars {
  path {
    @include custom-theme(dark-theme, fill, primary);
  }
}

.chip {
  padding: 12px 24px;
  margin-right: 24px;
  border-radius: 60px;
  color: $white;
  background-color: $primary;
  white-space: nowrap;
  cursor: pointer;
  z-index: 1;
}

a.chip:hover {
  text-decoration: none !important;
  color: $white !important;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-bar {
  position: sticky;
  top: 30px;
  left: 0;
  z-index: 999;
}

.back-button {
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  border-radius: 8px;
  max-width: fit-content;

  @include theme(color, primary);
  background: #ffffffcf;

  padding: 10px 20px;
  border-radius: 70px;

  &:hover {
    @include theme(background-color, primary);
    @include theme(color, background);
    // @include theme(stroke, background);

    .arrow path {
      @include theme(stroke, background);
    }

    ::ng-deep .arrow path {
      @include theme(stroke, background);
    }
  }
}

.dark-theme .back-button {
  @include custom-theme(dark-theme, background-color, accent);
  //   @include custom-theme(dark-theme, color, primary, true);

  //   &:hover {
  //     @include custom-theme(dark-theme, background-color, foreground);
  //   }

  //   .arrow path {
  //     @include custom-theme(dark-theme, stroke, primary);
  //   }
}

.slide-icon {
  #standing-frame {
    @include theme(stroke, primary);
  }

  #closing-frame,
  #opening-frame {
    @include theme(fill, primary);
  }

  &:hover {
    #standing-frame {
      @include theme(stroke, background);
    }

    #closing-frame,
    #opening-frame {
      @include theme(fill, background);
    }
  }
}

// .dark-theme .slide-icon {
//   #standing-frame {
//     @include custom-theme(dark-theme, stroke, primary);
//   }

//   #closing-frame,
//   #opening-frame {
//     @include custom-theme(dark-theme, fill, primary);
//   }

//   &:hover {
//     #standing-frame {
//       @include custom-theme(dark-theme, stroke, primary);
//     }

//     #closing-frame,
//     #opening-frame {
//       @include custom-theme(dark-theme, fill, primary);
//     }

//   }
// }

.fullscreen-icon {
  path {
    @include theme(stroke, primary);
  }

  rect {
    @include theme(fill, primary);
  }

  &:hover path {
    @include theme(stroke, background);
  }

  &:hover rect {
    @include theme(fill, background);
  }
}

// .dark-theme .fullscreen-icon {
//   path {
//     @include custom-theme(dark-theme, stroke, primary);
//   }

//   rect {
//     @include custom-theme(dark-theme, fill, primary);
//   }

//   &:hover path {
//     @include custom-theme(dark-theme, stroke, primary);
//   }

//   &:hover rect {
//     @include custom-theme(dark-theme, fill, primary);
//   }
// }

.icon-container {
  .arrow path {
    @include theme(stroke, primary);
  }

  ::ng-deep .arrow path {
    @include theme(stroke, primary);
  }
}

.dark-theme .icon-container {
  .arrow path {
    @include custom-theme(dark-theme, stroke, primary);
  }
}

.contact-bubble {
  height: 0px;
  width: 0px;
  @include theme(background-color, primary);
  border-radius: 100% 100% 0% 100%;
  position: fixed;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  z-index: -1;
  border: 3px solid rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease-in-out, background-color 0.8s ease-in-out;
  opacity: 0;

  icon-write-envelope {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  path {
    @include theme(stroke, background);
  }

  ellipse {
    @include theme(fill, background);
  }

  &.show-in-menu.visible {
    z-index: 3;
  }

  &.visible {
    z-index: 2;
    height: clamp(32px, 20vw, 80px);
    width: clamp(32px, 20vw, 80px);
    right: 32px;
    bottom: 32px;
    opacity: 1;

    img {
      height: 24px;
    }
  }

  &:hover {
    transform: translate(-1px, -2px);
    box-shadow: 0px 4px 10px 4px rgb(0 0 0 / 8%) !important;
  }

  &:active {
    transform: translate(1px, 2px);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
  }

  img {
    height: 0px;
  }
}

.dark-theme .contact-bubble {
  @include custom-theme(dark-theme, background-color, accent);

  path {
    @include custom-theme(dark-theme, stroke, primary);
  }

  ellipse {
    @include custom-theme(dark-theme, fill, primary);
  }
}

.d-none-green {
  @include d-none-for(green-theme);
}

.d-none-dark-blue {
  @include d-none-for(dark-blue-theme);
}

.d-none-light {
  @include d-none-for(light-theme);
}

.d-none-dark {
  @include d-none-for(dark-theme);
}

.d-block-dark {
  @include d-block-for(dark-theme, true);
}

.timeline-scroll {
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }

  // &::-webkit-scrollbar {
  //   width: 1.5em;
  // }

  // &::-webkit-scrollbar-track {
  //   box-shadow: none;
  //   @include transparent-bg(background, 0.9);
  // }

  // &::-webkit-scrollbar-thumb {
  //   @include theme(background-color, primary);
  //   border-radius: 1.5em;
  // }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  display: flex;
  overflow-x: scroll;

  // scroll-snap-type: x mandatory;

  &.active {
    cursor: url("./../assets/cursors/closedhand.cur");
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }

  -webkit-font-smoothing: subpixel-antialiased;
  // transform: perspective(1px) translateZ(0);
}

.scroll-control {
  font-size: 55px;
  width: 110px;
  height: 110px;
  z-index: 4;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .scroll-control-visual {
    @include transparent-bg(primary, 0.95);
    box-shadow: 0px 4px 10px 4px rgb(0 0 0 / 8%) !important;
    transition: transform 0.2s ease-in, background-color 0.8s ease-in-out,
      opacity 0.2s ease-in-out;
    position: absolute;
    width: 70%;
    height: 70%;
    top: 15%;
    left: 15%;
    border-radius: 100px;
  }

  &.visible {
    opacity: 1;
    display: flex;
  }

  &.removed {
    opacity: 1;
    display: none !important;
    pointer-events: none;
  }

  &.hidden {
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }

  path {
    transition: stroke 0.8s ease-in-out;
    // stroke: $dark;
    // @include theme(stroke, foreground);
    @include theme(stroke, background);
  }

  ::ng-deep path {
    transition: stroke 0.8s ease-in-out;
    // stroke: $dark;
    // @include theme(stroke, foreground);
  }

  &:hover > .scroll-control-visual {
    box-shadow: 0px 6px 14px 4px rgb(0 0 0 / 8%) !important;
    transform: scale(1.1);
  }

  &:active > .scroll-control-visual {
    transform: scale(0.9);
  }

  &.scroll-control-left {
    left: 24px;
    top: 50%;
  }

  &.scroll-control-right {
    right: 24px;
    top: 50%;
  }
}

@-moz-document url-prefix() {
  .scroll-control {
    display: none !important;
  }
}

.project-card-indicators > .scroll-control {
  position: fixed;
}

#timeline-project-cards > .scroll-control {
  position: absolute;

  // &.scroll-control{
  //   @include transparent-bg(primary, 0.95);
  // }

  &.scroll-control-left {
    left: 54px;
  }
  &.scroll-control-right {
    right: 54px;
  }
}

::ng-deep .dark-theme .scroll-control path {
  stroke: $white;
}

.no-decoration {
  text-decoration: none !important;
}

.flex-direction-column {
  flex-direction: column;
}
